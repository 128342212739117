import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Funktion zum Anmelden mit der Weiterleitungs-URL
export const signInWithRedirect = async (email, password) => {
  const { error } = await supabase.auth.signIn(
    { email, password },
    { redirectTo: process.env.REACT_APP_REDIRECT_URL }
  );
  if (error) console.error('Error signing in:', error.message);
};
