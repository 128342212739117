import React, { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import Login from './Login';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const session = supabase.auth.session();
    setUser(session?.user ?? null);

    supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error logging out:', error.message);
  };

  return (
    <div className="App">
      <h2>Hier entsteht eine Cloud App</h2>
      {user ? (
        <>
          <p>Welcome, {user.email}!</p>
          <button onClick={handleLogout}>Log Out</button>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
